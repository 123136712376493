import { FC, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { currentClientAtom } from '../../../../recoil/atoms/Clients';
import { Option } from '../../../Option';
import ActionPreview from '../../ActionPreview';
import { currentUserAtom } from '../../../../recoil/atoms/Auth';
import ClientFormService from '../../../../services/ClientFormService';
import ClientTemplateFormService from '../../../../services/ClientTemplateFormService';
import { ActionPreviewBaseProps } from '../ActionBaseProps';
import Loader from '../../../shared/Loader';
import { useTranslation } from 'react-i18next';
import { ParseKeys } from 'i18next';
import { DocumentResponse } from '../../../../models/Document';
import FormUtils from '../../../../utils/FormUtils';
import { FormRendererType, useFormRendererInfo } from '../../../../contexts/FormRendererContext';

type FormAnswer = DocumentResponse & { answeredSubtitle: string; isLoading?: boolean; failed?: false };

const DocumentReferencePreview: FC<ActionPreviewBaseProps<Option<string, string>[]>> = (props) => {
  const { answerOnly, response, data } = props;
  const { question, previewQuestion, previewDescription } = data;
  const currentClient = useRecoilValue(currentClientAtom);
  const currentUser = useRecoilValue(currentUserAtom);
  const { type } = useFormRendererInfo();
  const [forms, setForms] = useState<Record<string, FormAnswer>>({});
  const { t } = useTranslation('common');

  const title = useMemo(() => previewQuestion || question, [previewQuestion, question]);

  const clientFormTemplateService = useMemo(() => currentClient && new ClientTemplateFormService(currentClient.id), [currentClient]);

  useEffect(() => {
    const responses = response || [];

    setForms((prev) => ({ ...prev, ...Object.assign({}, ...responses.map((x) => ({ [x.id]: { isLoading: true } }))) }));
    Promise.allSettled(
      responses.map((resp) =>
        ClientFormService.getForm(resp.id, [404, 403]).catch((err) => {
          throw { ...err, id: resp.id };
        }),
      ),
    ).then((allResponses) => {
      for (const res of allResponses) {
        if (res.status === 'fulfilled') {
          setForms((prev) => ({
            ...prev,
            [res.value.data.id]: { ...res.value.data, answeredSubtitle: res.value.data.subtitle, isLoading: false } as FormAnswer,
          }));
        } else {
          let errorMessage: ParseKeys<'common'> = 'collapsed-document.error.unkown';
          if (res.reason?.meta?.code === 403 || res.reason.status === 403) {
            errorMessage = 'collapsed-document.error.permissions';
          } else if (res.reason?.meta?.code === 404 || res.reason.status === 404) {
            errorMessage = 'collapsed-document.error.notfound';
          }
          setForms((prev) => ({
            ...prev,
            [res.reason?.id]: { answeredSubtitle: t(errorMessage), isLoading: false, failed: true },
          }));
        }
      }
    });
  }, [clientFormTemplateService, currentClient, currentUser, response, t]);

  const answer = useMemo(() => {
    if (!response) {
      return ['-'];
    }

    return Object.values(forms).map((clientForm, i) => {
      if (clientForm.isLoading) {
        return <Loader key={`loader-${i}`} size={8} centered={false} />;
      }
      if (clientForm.failed) {
        return <div key={`subtitle-${i}`}>{clientForm.answeredSubtitle}</div>;
      }
      return type === FormRendererType.Platform ? (
        <Link
          onClick={(e) => {
            e.stopPropagation();
          }}
          key={`link-${i}`}
          to={`/clients/${currentClient?.id}/forms/${clientForm.id}/preview`}
          className="block underline"
          target="_blank"
          rel="noreferrer"
        >
          {FormUtils.getDocumentTitle(clientForm)} v{clientForm.majorVersion}
        </Link>
      ) : (
        <div key={`span-${i}`}>
          {FormUtils.getDocumentTitle(clientForm)} v{clientForm.majorVersion}
        </div>
      );
    });
  }, [currentClient?.id, forms, response, type]);

  if (answerOnly) {
    return <>{answer}</>;
  }

  return <ActionPreview data-cy="document-reference-preview" question={title} description={previewDescription} answer={answer} />;
};

export default DocumentReferencePreview;
